//import { SENTRY_DSN } from "sentry.client.config";
// Allowed sources for CSP. Add new 3rd party sources here.
const AllowedSources = {
  API: process.env.GALILEO_API_CLUSTER_URL,
  DATA: process.env.NEXT_PUBLIC_API_URL?.replace('api.', 'data.'),
  PUBLIC_API: process.env.NEXT_PUBLIC_API_URL,
  GLEAP: '*.gleap.io',
  GLEAP_WS: 'wss://*.gleap.io',
  POSTHOG: '*.posthog.com',
  SENTRY: '*.sentry.io',
  SLACK: '*.slack.com'
} as const;

export const cspHeader = `
    default-src 'self';
    connect-src 'self' ${Object.values(AllowedSources)
      .filter((x) => Boolean(x))
      .join(' ')};
    frame-src 'self' ${AllowedSources.GLEAP};
    script-src 'self' 'unsafe-eval' 'unsafe-inline' ${AllowedSources.GLEAP};
    style-src 'self' 'unsafe-inline';
    img-src 'self' blob: data: ${AllowedSources.GLEAP};
    font-src 'self';
    media-src 'self' ${AllowedSources.GLEAP};
    object-src 'none';
    base-uri 'self';
    form-action 'self';
    frame-ancestors 'none';
    upgrade-insecure-requests;
`
  .replace(/\s{2,}/g, ' ')
  .trim();
