globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"8b390ec2b75c5cbad6772b0455e67b920884793d"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';

export const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN || 'https://0fba8bbb240e4080f50bad52ed0ba10b@o1166153.ingest.us.sentry.io/6256456';

// Disable on local development
const enabled = process.env.NODE_ENV !== 'development';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.captureConsoleIntegration({
      levels: ['error']
    }),
    Sentry.httpClientIntegration({
      failedRequestStatusCodes: [400, 599]
    })
  ],
  tracesSampleRate: 0.1,
  profilesSampleRate: 0,
  enabled,
  environment: process.env.GALILEO_CUSTOMER_NAME
});
